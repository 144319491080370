<template>
  <div class="textWrapper">
    <p
      style="
        font-size: 18px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: center;
      "
    >
      【昂立直播课堂】用户个人信息保护政策
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      版本生效时间：【2022】年【6】月【24】日
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      版本更新时间：【2022】年【6】月【24】日
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我方深知个人信息对用户的重要性，并将按照法律法规等要求，采取相应安全保护措施，尽力保护您（以下或简称“用户”）的个人信息安全可控。有基于此，我方制定本《【昂立直播课堂】用户个人信息保护政策》（以下简称“本政策”），本政策主要向您阐述我方收集个人信息的范围、用途以及您所享有的权利等，并申明了我方对保护用户个人信息的承诺。我方未来可能根据信息处理情境的变化对本政策进行更新或修改。我方承诺将按照本政策收集、使用和披露用户信息，本政策适用于【昂立直播课堂】的所有服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      在使用我方的服务前，请您务必仔细、审慎阅读并充分理解本政策，在确认充分理解并同意后使用相关服务。如您在【昂立直播课堂】进行账号注册/登录过程中勾选“同意”选项，即表示您已充分理解并完全接受本政策项下的全部条款，本政策对您及我方均具有法律约束力。如果您不同意本政策中任一条款，应立即停止注册程序，我方也将无法为您提供服务。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1、 定义
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.1.
      我方：【昂立直播课堂】运营主体的单称或合称，指【运营主体名称】及其关联主体。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.2.
      用户：注册、登录、使用本软件的法人、非法人组织或自然人，包括本协议中定义的“机构”和“学生”
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.3.
      机构：使用【昂立直播课堂】直播软件的合法成立并存在的学校、企业、其他非企业单位、工作室等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.4. 个人账号：使用【昂立直播课堂】直播软件的教师账号、学生账号。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.5.
      本服务：我方基于互联网以网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务和功能，包括但不限于课程直播、聊天、答题、抢答等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.6.
      合作伙伴：指【昂立直播课堂】服务提供过程中涉及的第三方信息系统、支付机构、微信/QQ/微博等用于第三方登录的社交平台，以及【昂立直播课堂】为实现部分功能、提供更好的客户服务和用户体验而进行合作的其他第三方主体或平台。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.7. 本软件：由【运营主体名称】及其关联主体提供的【昂立直播课堂】软件。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.8. 本政策：指本《【昂立直播课堂】用户个人信息保护政策》。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.9.
      个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本政策中的个人信息包括：姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      1.10.
      个人敏感信息：是指一旦泄露、非法提供或滥用可能危人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本政策中的个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2、 我方如何收集您的信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我方将出于本政策所述之目的，收集您的如下个人信息并获取如下权限：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.
      为了向您提供更好的服务，您将向我方提供以下信息，如果您不提供相关信息，可能会影响您使用相关特定功能：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.1. 注册信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您注册机构账号，您需要联系我方进行身份核验，并由我方代为创建后台管理账号。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您注册个人账号，您需通过所在的机构向我方提交您的手机号帮您代注册账号。为便于我方为您提供基础的服务，我方将收集您的手机号、密码，否则我方无法为您创建个人账号并正常向您提供服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      在您注销本软件账号后，我方将停止为您提供相关服务，并根据法律规定删除您的个人信息，或进行匿名处理。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.2. 课程学习
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您为教师账号，您使用我方提供的课程服务时，为了记录您的教学记录，我方与机构用户可能需要收集下列信息：课件使用情况、教师行为（禁言、奖励、举手、上下台）、教学工具使用情况（定时器、筛子、抢答器、答题器、白板操作、文件共享、计时器等）等，以供机构用户了解您的教学情况。如您选择不提供上述信息，我方可能无法向您提供直播服务，但不影响您正常使用本软件的其他功能。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您为学生账号，您使用我方提供的课程服务时，为了记录您的课程学习记录，我方与机构用户可能需要收集下列信息：课节时间、上课时长、学生考勤、学生评语、教师评语、学生行为（发言、摄像头开启、聊天、上下台、移除、奖励、举手）、教学工具使用情况（白板操作、抢答器、答题器）、课程回放数据等。我方将应用这部分信息分析您的学习状态，为我方的服务提供优化参考依据。如您选择不提供上述信息，我方可能无法向您提供直播服务，但不影响您正常使用本软件的其他功能。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      请您注意，机构可能会基于教学需要录制上课视频，我方会在本软件界面向您进行提示。如您不希望您在课堂中的教学、上课行为被录入上课视频，请您直接与所在的机构进行联系。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      为协助机构进行课程管理，我方可能会与机构共同收集课程的上课图片、录课视频、课程名、课节名、开课时间、课节时长、台上人数、用户进入时间、用户退出时间和原因、录课信息，上述信息可能包含您的个人信息。此外，为了确保您稳定使用我方的服务，及时帮助您解决可能出现的技术问题，我方还会与机构共同收集您的网关数据、摄像头信息、麦克风信息、扬声器信息等。如您选择不提供上述信息，我方可能无法向您提供直播服务，但不影响您正常使用本软件的其他功能。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.3. 聊天分享
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      当您使用聊天功能时，我方可能与机构共同收集您在聊天中分享的信息，包括您在聊天中公开分享的文字、语音、图片、电子文档等。请您注意，这其中可能涉及他人的个人隐私、知识产权或其他合法权益，如涉及他人个人隐私、知识产权或其他合法权益，请您在征得他人授权后再行分享。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      当您使用聊天功能时，我方还可能与机构共同收集您的好友列表、群列表、群成员资料等信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.4. 网络设置及设备信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      为了保障网络访问和服务的基本安全，我方可能与机构共同收集您在使用本软件时的网络设置及设备信息，例如设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）、网络延迟、丢包率等。如您选择不提供上述信息，我方可能无法向您提供服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.5. 用户服务
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      当您与我方的客服团队联系时，我方会以通话录音及人工录入的方式记录您的通话信息、您提出的意见或建议、您提供的姓名、联系方式，以便我方处理纠纷、解决问题或向您反馈结果。如果您拒绝我方收集此类信息，可能会导致您无法拨打我方的客服电话或客服团队无法将处理结果反馈到您。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.1.6. 来自第三方的信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      目前，除机构用户为代注册、教学管理的目的向我方提供个人信息外，我方不会主动从其他第三方获取您的个人信息。如未来因业务发展或其他原因需要从第三方间接获取您的个人信息，我方会通过本政策或其他方式向您进行说明；我方也会遵守相关法律法规规定，要求第三方保障提供信息的合法性。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.2.
      为了向您提供更好的服务，您将向我方开通以下相关权限。如不开启权限，您将不能使用与此权限相关的特定功能，但不影响您使用本软件提供的基本服务：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.2.1. 读取及写入存储器权限
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      为了正常使用本软件内的图片、视频、文件、资料、讲义等资源及视频录制、头像上传、图片预览、文件上传、图文聊天、云盘存储、磁盘空间判断等功能，我方需要获取您的读取及写入存储器权限。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.2.2. 通讯录权限
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您希望通过我方向您推荐您手机通讯录上已注册使用本软件的联系人以便您添加好友或邀请通讯录好友使用本软件，您可以开启通讯录权限，以便直接添加通讯录好友；如您选择不开启通讯录权限，您将无法使用添加或邀请通讯录好友功能，但这不会影响您正常使用本软件的其他功能。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.2.3. 相机、相册及麦克风权限
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      当您使用头像图片设置、视频直播、语音连麦互动、云盘存储、图文聊天、扫一扫等功能时，我方需要获取您的相机、相册和/或麦克风权限。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3. 征得授权同意的例外
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      根据法律、法规、规章等相关规定，以下情形中，我方可能会依法收集并使用您的个人信息无需征得您的同意：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.1. 与国家安全、国防安全相关的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.2. 与公共安全、公共卫生、重大公共利益直接相关；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.4.
      出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.5. 所收集的个人信息是您自行向社会公众公开的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.6.
      从合法公开披露的信息中收集个人信息的，如：合法的新闻报道、政府信息公开等渠道；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.7. 根据您的要求签订和履行合同所必须的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.8.
      用于维护所提供的服务的安全稳定运行所必需的，例如发现或处置本服务的故障；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.9.
      为新闻单位或学术研究机构基于新闻报道、公共利益开展统计或学术研究所必要等，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理，且数据接收方无法复原并重新识别个人信息的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      2.3.10. 法律法规规定的其他情形。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      3、 我方如何使用您的信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，您的信息将用于下列用途，如我方需将收集到的相关信息用于新的目的、范围或实际传输方式与本政策约定不一致时，我方将以弹窗提示方式通知到您并取得您的明示同意：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      3.1. 向您提供我方的服务，并维护、改进、优化这些服务及服务体验。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      3.2.
      对您的个人信息进行分析和研究，统计我方的服务使用情况，并可能会与公众或第三方分享这些统计信息，以展示本服务的整体使用趋势，但我方尽可能将该信息进行去标识化和匿名化。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      3.3.
      使用您的个人信息以验证身份，预防、发现、调查可能存在的欺诈、危害安全、非法或违反与我方的协议、政策或规则的行为，以保护您、其他用户或我方的合法权益。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      4、 我方如何使用cookies和同类技术
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      4.1.
      为使您获得更轻松的访问体验，您在使用本软件时，我方可能会通过采用各种技术收集和存储您访问本软件的数据信息（包括您的设备型号、操作系统、唯一设备标识符、ANDROID ID、登录IP地址信息、以及缓存您的浏览信息、点击信息等），当您访问或再次访问时,我方能识别您的身份，并通过分析数据为您提供更好更多的服务。我方向您发送Cookies是为了简化您重复登录的步骤、为您提供更好的偏好设置或帮助判断您的登录状态以及账号或数据安全。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      4.2.
      请您理解，我方的某些服务只能通过使用Cookies才可得到实现。如果您不愿意接收cookies，您可以选择更改浏览器设置来拒绝所有cookies，或者每次在您浏览器接收到cookies时由电脑通知您，由此您可以自行决定接收或拒绝cookies。但是这样的操作可能使您无法使用依赖于cookies的部分功能。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      4.3. 网页上常会包含一些电子图像，称为“单像素 GIF 文件”或“网络
      beacon”，它可以帮助网站计算浏览网页的用户或访问某些cookies。我方会通过网络beacon收集您浏览网页活动信息，包括您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5、 我方如何共享、转让、披露您的信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.
      我方承诺对您的信息进行严格保密，不会与其他第三方的公司、组织或个人共享您的个人信息，但以下情况除外：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.1.
      在获取明确同意的情况下共享：获得您的明确同意后，我方会与其他方共享您的个人信息；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.2.
      在法定情形下的共享：我方可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.3.
      为方便同班级、同课程同学、教师之间的沟通，本软件的其他用户可看到您账号对应的昵称、头像、性别、年龄、地区等信息，以便同您进行信息及语音通话等交流，我方会与您在本软件的联系人共享您的个人资料信息（如昵称、头像、性别、年龄、地区）；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.4.
      我们可能会与第三方合作方共同为您提供某些服务或由这些合作方在我们的APP中独立为您提供某项服务，在此过程中我们可能会与合作方共享您的个人信息或者合作方通过应用程序接口（API）、软件工具开发包（SDK）等方式自行获取您的个人信息。若我们与合作方共享您的个人信息，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。若合作方自行获取您的个人信息，我们会对合作方获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与其签署严格的保密协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。我们接入的授权合作伙伴的SDK详见：
      <a href="https://oc-download.onlyeduschool.com/android-protocol"
        >https://oc-download.onlyeduschool.com/android-protocol</a
      >
      。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我们的合作伙伴包括以下类型：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      a）技术服务提供商：我们将信息发送给在全球范围内支持我们业务的技术服务提供商，这些支持包括提供技术基础设施服务、统计分析服务的服务商。我们合作的技术服务商腾讯公司会通过腾讯实时音视频SDK，通讯即时通讯SDK，腾讯浏览器SDK等收集您的设备信息来提供相应的技术服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.2. 我方不会将您的个人信息转让给其他公司、组织和个人，但以下情况除外：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.2.1.
      在获取明确同意的情况下转让：获得您的明确同意后，我方会向其他方转让您的个人信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.2.2.
      在我方计划与其他公司合并、收购、破产清算或进行其他资本市场活动时，以及其他情形下我方需要接受来自其他主体的尽职调查时，我方会把您的信息提供给必要的主体，但我方会通过和这些主体签署保密协议等方式要求其对您的个人信息采取合理的保密措施。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.3. 我方不会将您的个人信息披露给其他公司、组织和个人，但以下情况除外：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.3.1. 根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.3.2.
      为维护我方或其他用户的合法权益，在协助处理与您有关的交易纠纷或争议时，我方可能向您的交易相对方或存在利害关系的第三方提供解决交易纠纷或争议所必需的信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.3.3.
      根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我方可能会依据所要求的个人信息类型和披露方式向其披露您的个人信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.
      如符合本政策第2.3条约定的情形，我方共享、转让、披露您的个人信息无需事先征得您的授权同意。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6、 我方如何保存和保护您的个人信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1. 我方如何保存您的个人信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.1.
      按照相关法律法规规定，我方将把中华人民共和国境内运营过程中收集和产生的您的个人信息存储在中国境内。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.2.
      我方将在为提供服务所必需的期间内保留您的个人信息，但您要求我方立即删除或注销账号的、或法律法规另有规定的除外。在您的个人信息超出保留期间后，我方会根据法律法规的要求删除您的个人信息，或使其匿名化处理。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2. 我方如何保护您的个人信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.1.
      我方会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，我方有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.2.
      我方有先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.3.
      我方会采取合理可行的措施，尽力避免收集无关的个人信息，并在限于达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期限或在法律的允许期限内。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.4.
      本软件含有到第三方网站的链接，我方不对任何第三方网站的隐私保护措施负责。当您登陆第三方网站时，请提高警惕，注意个人信息安全。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.5.
      如发生个人信息安全事件后，我方将按照法律法规的要求并于30个自然日内向您告知：安全事件的基本情况和可能的影响、我方已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我方将以本政策第12.1条约定的方式告知您，难以逐一告知个人信息主体时，我方会采取合理、有效的方式发表公告。同时，我方还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.6.
      由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我方将尽力确保您提供给我方的个人信息的安全性。请您知悉并理解，您接入我方的服务所用的系统和通讯网络，有可能因我方可控范围外的因素而出现问题。因此，我方强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码等个人信息透露给他人。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.3. 我方如何保护未成年人的信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.3.1.
      我方重视未成年人的个人信息保护，如您为未满14周岁的儿童或未满18周岁的未成年人，请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我方的服务或向我方提供信息。对于经父母或监护人同意使用我方的服务而收集未成年人个人信息的情况，我方只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如您的监护人不同意您按照本政策使用我方的服务或向我方提供信息，请您立即终止使用我方的服务并及时通知我方，以便我方采取相应的措施。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.3.2.
      为加强对未成年人个人信息的保护，除遵循本政策项下的其他规定外，我方将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本政策的规定保护未成年人的个人信息。具体内容如下：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      a)
      我方就所收集的未成年人信息和未成年人以外的用户个人信息分别存储，将采取不低于同行业的加密措施存储未成年人信息，确保未成年人信息安全。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      b)
      我方将指定专人负责未成年人信息保护，任何工作人员访问未成年人的个人信息的，必须经未成年人信息保护人员审批（包括电子邮件、短信、微信此类电子信息交互方式）并形成访问情况的记录；同时，采取不低于同行业的技术措施，避免违法复制、下载未成年人的个人信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      c)
      如我方需向第三方转移未成年人的个人信息时，除再次征得未成年人监护人的同意及满足上述原则外，将自行或者委托第三方机构进行安全评估，并形成评估报告。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7、 您如何管理个人信息
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      根据法律法规的要求，我方将采取适当的技术手段，保证您访问、更新、更正或删除您的个人信息的权利。如有法律法规的要求导致我方无法满足您的请求的，我方将向您提供合理的解释。您对您的个人信息享有以下权利：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.1. 访问权
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      您有权访问您的账号信息、学习记录、课程信息、聊天记录、云盘信息等。您登录账号后，即可访问我方保留的您的个人信息。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.2. 更正权
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      您有权通过本软件登录个人账号后，修改您原有的个人信息，包括但不限于账号名称、头像、昵称、年龄等。当您发现我方处理关于您的个人信息有错误且您无法在账号内自行修改时，您有权通过本政策第12.2条约定的联系方式联系我方，要求我方对错误或不完整的信息做出更正或更新。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.3. 撤销权
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      每个业务功能需要一些基础的个人信息才能得以完成。如您需要改变您授权同意的范围或撤回授权，您可以通过解除绑定、修改个人设置、删除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销授权。如果您无法撤销以上个人信息的授权，您可以随时联系我方解决。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.4. 删除权
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      当您不再使用我方的服务时，或我方违反法律法规或与您的约定收集、使用、与他人分享您的个人信息时，您有权删除您提供的信息。您可以在本软件使用账号编辑的功能对信息进行删除，也可通过本政策第12.2条约定的联系方式与客服取得联系，提交删除申请。在满足本政策、《【昂立直播课堂】用户服务协议》要求及法律法规要求的最短保存期限的情况下，我方将对您的个人信息进行删除或匿名化处理，但这样可能导致我方无法向您提供相应的服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.5. 注销权
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您为个人账号，你可以通过联系您所在的机构注销您的账号。如您的账号在注销前有未履行完毕的权利义务，请您务必与您所在的机构就相关权利义务的处理达成一致意见后再注销账号，避免产生不必要的纠纷。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      如您为机构账号，您可以通过联系我方客服的方式注销您的账号。在您注销帐号前，我方会要求您进行必要的身份验证，包括机构信息、安全状态、设备信息等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      您知悉并理解，注销帐号的行为是不可逆的行为，在您注销帐号后，我方将停止为您提供给服务，并将删除有关您帐号的一切信息或对相关信息进行匿名化处理，但法律法规另有规定的除外。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      7.6.
      如符合本政策第2.3条约定的情形，您更正、撤销、删除或注销有关信息或授权的请求可能无法得到满足。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8、 您的信息如何在全球范围内转移
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我方在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内，不会存在个人信息出境之情况。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9、 争议解决
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.1.
      本政策之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律。如法律无相关规定的，参照商业惯例及/或行业惯例。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.2.
      您因使用本软件服务所产生及与本软件服务有关的争议，由我方与您协商解决。协商不成时，任何一方均可向【运营主体名称】所在地人民法院提起诉讼。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10、 版本更新
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.1.
      我方的个人信息保护政策可能基于各种原因进行变更。我方将在本软件上对本政策所做的任何变更进行公示，您有责任经常性地浏览关于变更本政策的公示。在变更做出后您如果继续使用本软件则被视为您同意对本政策的变更；如果您不接受本政策的任何变更，您可选择注销账号。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.
      对于重大变更，我方还会提供更为显著的通知（如短信通知、邮件通知、系统弹窗等）。本政策所指的重大变更包括但不限于：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.1. 我方的服务模式发生重大变化。如处理个人信息的目的、
      处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.2.
      我方在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.3. 个人信息共享、转让或公开披露的主要对象发生变化；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.4. 您参与个人信息管理方面的权利及其行使方式发生重大变化；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.5. 我方负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11、 其他
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.
      本政策为《【昂立直播课堂】用户服务协议》不可分割的组成部分。本政策与《【昂立直播课堂】用户服务协议》不一致的，以本政策约定为准。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.2.
      本政策任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本政策其余条款的有效性及可执行性。
      &nbsp;&nbsp;
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.3. 本政策所有条款的标题仅为阅读方便，不作为本政策涵义解释或限制的依据。
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12、 联系方式
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1. 您同意我方以下列方式之一向您送达各类通知：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1.1. 公示的文案；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1.2. 站内消息、弹窗消息、客户端推送的消息；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1.3. 通过您账号绑定的第三方软件（如微信）；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1.4.
      根据您预留于本软件的联系方式发出的电子邮件、手机短信、函件、人工电话联系等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.2.
      如您就个人信息问题需要任何帮助或有任何投诉或建议，可以通过以下方式与我方联系，我方将尽快审核您的问题、投诉或建议，并于15个工作日内予以回复：
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      运营主体名称： 【上海新南洋教育科技有限公司】
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      注册地址：【上海市徐汇区番禺路667号6楼C座】
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      联系电话：【021-52588866】
    </p>
    <p style="text-align: left"></p>
    <p style="text-align: left"></p>
  </div>
</template>
<script>
export default {
  data: () => ({}),
}
</script>
<style lang="scss" scoped>
.textWrapper {
  .head {
    text-align: center;
  }

  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  padding: 20px;
}
</style>
