<template>
  <div class="main-container">
    <vs-navbar v-model="active" text-white :color="'#FF4C00'" center-collapsed>
      <template #left>
        <img src="@/assets/logo.svg" alt="" height="45" />
      </template>
      <template #right>
        <vs-navbar-item
          id="primary"
          :active="active == 'primary'"
          href="#"
          target="_self"
        >
          首页
        </vs-navbar-item>
        <vs-navbar-item
          id="success"
          :active="active == 'success'"
          href="#downloads"
          target="_self"
        >
          更多下载
        </vs-navbar-item>
        <vs-navbar-item
          id="danger"
          :active="active == 'danger'"
          href="#history"
          target="_self"
        >
          历史版本
        </vs-navbar-item>
        <vs-navbar-item
          id="warn"
          :active="active == 'warn'"
          href="#doc"
          target="_self"
        >
          文档和帮助
        </vs-navbar-item>
        <vs-navbar-item
          id="admin"
          :active="active == 'admin'"
          href="https://ocadmin.onlyeduschool.com/login.html"
        >
          管理后台
        </vs-navbar-item>
      </template>
    </vs-navbar>
    <div class="full-container">
      <div class="heroBanner bg-color">
        <vs-row>
          <vs-col :w="2" />
          <vs-col :w="8">
            <div class="bannerWrap">
              <h3>OnlyClass</h3>
              <h1>昂立直播课堂</h1>
              <h5>让课堂更精彩</h5>

              <vs-button
                size="large"
                gradient
                @click="download('windows')"
                v-if="os === 'Win32'"
              >
                <img src="@/assets/windows-white.svg" alt="" width="20" />
                <span class="ml-2">立即下载</span>
              </vs-button>
              <vs-button
                size="large"
                gradient
                @click="download('mac')"
                v-if="os === 'MacIntel'"
              >
                <img src="@/assets/apple.svg" alt="" width="25" />
                <span class="ml-2">立即下载</span>
              </vs-button>
              <h6>当前版本号：{{ this.$commonData.versionList[0].version }}</h6>
            </div>
          </vs-col>
          <vs-col :w="2" />
        </vs-row>
      </div>
    </div>
    <div id="downloads" class="full-container">
      <div class="container">
        <h3 class="section-title-main">更多下载</h3>
        <h5 class="section-title-sub">选择适合您设备的平台版本</h5>
        <vs-row>
          <vs-col :w="3">
            <div class="card card-1" @click="download('windows')">
              <ul class="flex">
                <li class="img">
                  <img src="@/assets/windows.svg" alt="" width="65" />
                </li>
                <li class="text">
                  <p>Windows</p>
                </li>
              </ul>
            </div>
          </vs-col>
          <vs-col :w="3">
            <div class="card card-1" @click="download('mac')">
              <ul class="flex">
                <li class="img">
                  <img src="@/assets/iMac.svg" alt="" width="100" />
                </li>
                <li class="text">
                  <p>Mac</p>
                </li>
              </ul>
            </div>
          </vs-col>
          <vs-col :w="3">
            <div class="card card-1" @click="openDialog('ios')">
              <ul class="flex">
                <li class="img">
                  <img src="@/assets/iphone.svg" alt="" width="80" />
                </li>
                <li class="text">
                  <p>IOS</p>
                </li>
              </ul>
            </div>
          </vs-col>
          <vs-col :w="3">
            <div class="card card-1" @click="openDialog('android')">
              <ul class="flex">
                <li class="img">
                  <img src="@/assets/android-line.svg" alt="" width="80" />
                </li>
                <li class="text">
                  <p>安卓</p>
                </li>
              </ul>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <div id="history" class="full-container bg-dark">
      <div class="container">
        <h3 class="section-title-main">历史版本</h3>
        <h5 class="section-title-sub">查看历史版本及更新说明</h5>
        <div class="center">
          <vs-table striped>
            <template #thead>
              <vs-tr>
                <vs-th width="30%"> 版本 </vs-th>
                <vs-th width="50%"> 说明 </vs-th>
                <vs-th width="20%"> 日期 </vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr v-for="(tr, i) in versionList" :key="i">
                <vs-td>
                  {{ tr.version }}
                </vs-td>
                <vs-td>
                  {{ tr.desc | pureText }}
                </vs-td>
                <vs-td>
                  {{ tr.date }}
                </vs-td>

                <template #expand>
                  <div class="con-content">
                    <ul class="feature-list">
                      <li v-for="(item, index) in tr.desc" :key="index">
                        <p>{{ item }}</p>
                      </li>
                    </ul>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
    <div id="doc" class="full-container">
      <div class="container">
        <h3 class="section-title-main">文档与帮助</h3>
        <h5 class="section-title-sub">查看操作手册及用户指南</h5>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4">
            <div class="card card-1" @click="download('pdf-admin')">
              <div class="fjac">
                <svg
                  t="1610617214655"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3606"
                  width="100"
                  height="100"
                >
                  <path
                    d="M863.594 245.432v715.817H271.305c-61.206 0-110.824-49.618-110.824-110.824V63.609h512.095l191.018 181.823z"
                    fill="#D63C3C"
                    p-id="3607"
                  ></path>
                  <path
                    d="M672.576 63.609V264.37h191.018v-18.94z"
                    fill="#A50E0E"
                    p-id="3608"
                  ></path>
                  <path
                    d="M672.576 63.609v181.823h191.018z"
                    fill="#F76969"
                    p-id="3609"
                  ></path>
                  <path
                    d="M686.236 439.996c-9.772-5.192-27.684-4.17-53.29 3.07-17.31 4.93-37.635 12.626-58.563 22.177-11.456-2.745-23.374-5.875-33.472-10.932-27.193-13.548-53.157-35.867-73.263-62.932-0.116-3.05-0.256-5.727-0.423-8.432 0 0-8.245-71.685-18.256-93.769-1.379-3.022-1.918-3.863-3.5-6.072l-1.84-2.365c-5.495-6.56-14.257-12.63-24.15-9.21l-6.098 1.6c-11.177 3.32-18.599 11.75-18.453 20.998 0.686 28.98 20.109 66.85 49.144 114.76l-0.974 9.503c-2.692 26.629-7.78 54.142-12.282 78.437l-0.584 3.161c-4.736 25.52-9.44 47.389-14.003 66.093l-8.108 7.94c-0.599 0.583-14.482 14.19-17.648 17.676-26.984 29.334-42.114 58.02-40.47 76.73 0.522 5.975 3.514 12.93 10.348 14.525l10.689 1.913c4.638 0.82 9.194 0.584 13.551-0.71 23.362-6.94 41.836-44.096 59.823-120.393 38.957-26.859 84.607-53.12 125.737-72.337 38.292 8.73 82.432 9.572 107.903 2.006 4.517-1.341 8.288-2.924 11.205-4.716 4.505-2.746 7.789-6.747 9.177-11.284 2.732-8.907 0.683-19.44-3.76-29.53-1.334-2.998-4.955-6.105-8.426-7.926l-0.014 0.02zM374.245 667.952c0.789-12.917 10.81-40.953 29.683-68.784 1.18-1.738 3.968-6.499 6.51-10.916-13.718 49.334-26.262 71.084-36.193 79.7z m46.65-383.928c7.51-2.231 17.403 15.435 23.024 33.06 5.621 17.626 5.18 31.326 2.763 42.068-8.32-12.37-16.762-33.263-20.94-47.327 0 0-8.302-26.775-4.847-27.801z m27.938 255.427c2.442-10.922 4.967-22.408 7.42-34.528 5.936-29.656 8.547-52.27 10.025-70.655 19.538 19.311 41.162 34.226 64.392 44.423a185.696 185.696 0 0 0 9.103 3.714c-35.543 18.694-65.47 37.471-90.922 57.04l-0.018 0.006z m237.407-72.837c-1.876 2.12-8.218 4.91-12.451 6.167-13.674 4.061-32.447 2.834-59.184-0.288 8.922-3.383 17.164-6.197 24.656-8.423 13.71-4.072 17.767-5.335 32.192-5.9 14.423-0.565 16.663 6.326 14.791 8.463l-0.004-0.019z"
                    fill="#A50E0E"
                    p-id="3610"
                  ></path>
                  <path
                    d="M92.854 494.316h838.292v356.067H92.854z"
                    fill="#F76969"
                    p-id="3611"
                  ></path>
                  <path
                    d="M330.631 559.866c23.43 0 41.165 6.06 53.205 18.175s18.06 29.093 18.06 50.92c0 21.832-6.02 38.86-18.06 51.083-12.04 12.229-29.775 18.23-53.205 18.01h-70.94v95.618h-30.914V559.867h101.854z m-10.413 111.992c17.572 0.22 30.426-3.382 38.561-10.806 8.136-7.42 12.203-18.118 12.203-32.091 0-13.968-4.067-24.61-12.203-31.927-8.135-7.312-20.989-10.97-38.56-10.97h-60.527v85.794h60.526z m199.15-111.992c35.795 0 63.342 9.17 82.653 27.508 19.307 18.337 28.963 46.283 28.963 83.829 0 19.646-2.172 37.059-6.508 52.23-4.342 15.175-11.064 27.946-20.176 38.311-9.111 10.371-20.72 18.286-34.819 23.74-14.104 5.46-30.806 8.187-50.113 8.187h-79.726V559.866h79.726z m2.603 207.61c3.468 0 7.81-0.273 13.017-0.82 5.206-0.541 10.738-1.852 16.597-3.93 5.857-2.07 11.658-5.075 17.408-9.005 5.746-3.93 10.902-9.276 15.457-16.045 4.556-6.764 8.298-15.227 11.227-25.378 2.928-10.152 4.393-22.432 4.393-36.84 0-13.967-1.357-26.467-4.068-37.494-2.715-11.021-7.159-20.41-13.342-28.162-6.183-7.746-14.155-13.64-23.916-17.682-9.764-4.037-21.804-6.058-36.122-6.058h-52.066v181.412h51.415z m299.377-207.61v26.198H699.644v74.333H806.38v26.197H699.644v107.08H668.73V559.867h152.618z"
                    fill="#FFFFFF"
                    p-id="3612"
                  ></path>
                </svg>
              </div>
            </div>
            <h5>管理后台手册</h5>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4">
            <div class="card card-1" @click="download('pdf-teacher')">
              <div class="fjac">
                <svg
                  t="1610617214655"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3606"
                  width="100"
                  height="100"
                >
                  <path
                    d="M863.594 245.432v715.817H271.305c-61.206 0-110.824-49.618-110.824-110.824V63.609h512.095l191.018 181.823z"
                    fill="#D63C3C"
                    p-id="3607"
                  ></path>
                  <path
                    d="M672.576 63.609V264.37h191.018v-18.94z"
                    fill="#A50E0E"
                    p-id="3608"
                  ></path>
                  <path
                    d="M672.576 63.609v181.823h191.018z"
                    fill="#F76969"
                    p-id="3609"
                  ></path>
                  <path
                    d="M686.236 439.996c-9.772-5.192-27.684-4.17-53.29 3.07-17.31 4.93-37.635 12.626-58.563 22.177-11.456-2.745-23.374-5.875-33.472-10.932-27.193-13.548-53.157-35.867-73.263-62.932-0.116-3.05-0.256-5.727-0.423-8.432 0 0-8.245-71.685-18.256-93.769-1.379-3.022-1.918-3.863-3.5-6.072l-1.84-2.365c-5.495-6.56-14.257-12.63-24.15-9.21l-6.098 1.6c-11.177 3.32-18.599 11.75-18.453 20.998 0.686 28.98 20.109 66.85 49.144 114.76l-0.974 9.503c-2.692 26.629-7.78 54.142-12.282 78.437l-0.584 3.161c-4.736 25.52-9.44 47.389-14.003 66.093l-8.108 7.94c-0.599 0.583-14.482 14.19-17.648 17.676-26.984 29.334-42.114 58.02-40.47 76.73 0.522 5.975 3.514 12.93 10.348 14.525l10.689 1.913c4.638 0.82 9.194 0.584 13.551-0.71 23.362-6.94 41.836-44.096 59.823-120.393 38.957-26.859 84.607-53.12 125.737-72.337 38.292 8.73 82.432 9.572 107.903 2.006 4.517-1.341 8.288-2.924 11.205-4.716 4.505-2.746 7.789-6.747 9.177-11.284 2.732-8.907 0.683-19.44-3.76-29.53-1.334-2.998-4.955-6.105-8.426-7.926l-0.014 0.02zM374.245 667.952c0.789-12.917 10.81-40.953 29.683-68.784 1.18-1.738 3.968-6.499 6.51-10.916-13.718 49.334-26.262 71.084-36.193 79.7z m46.65-383.928c7.51-2.231 17.403 15.435 23.024 33.06 5.621 17.626 5.18 31.326 2.763 42.068-8.32-12.37-16.762-33.263-20.94-47.327 0 0-8.302-26.775-4.847-27.801z m27.938 255.427c2.442-10.922 4.967-22.408 7.42-34.528 5.936-29.656 8.547-52.27 10.025-70.655 19.538 19.311 41.162 34.226 64.392 44.423a185.696 185.696 0 0 0 9.103 3.714c-35.543 18.694-65.47 37.471-90.922 57.04l-0.018 0.006z m237.407-72.837c-1.876 2.12-8.218 4.91-12.451 6.167-13.674 4.061-32.447 2.834-59.184-0.288 8.922-3.383 17.164-6.197 24.656-8.423 13.71-4.072 17.767-5.335 32.192-5.9 14.423-0.565 16.663 6.326 14.791 8.463l-0.004-0.019z"
                    fill="#A50E0E"
                    p-id="3610"
                  ></path>
                  <path
                    d="M92.854 494.316h838.292v356.067H92.854z"
                    fill="#F76969"
                    p-id="3611"
                  ></path>
                  <path
                    d="M330.631 559.866c23.43 0 41.165 6.06 53.205 18.175s18.06 29.093 18.06 50.92c0 21.832-6.02 38.86-18.06 51.083-12.04 12.229-29.775 18.23-53.205 18.01h-70.94v95.618h-30.914V559.867h101.854z m-10.413 111.992c17.572 0.22 30.426-3.382 38.561-10.806 8.136-7.42 12.203-18.118 12.203-32.091 0-13.968-4.067-24.61-12.203-31.927-8.135-7.312-20.989-10.97-38.56-10.97h-60.527v85.794h60.526z m199.15-111.992c35.795 0 63.342 9.17 82.653 27.508 19.307 18.337 28.963 46.283 28.963 83.829 0 19.646-2.172 37.059-6.508 52.23-4.342 15.175-11.064 27.946-20.176 38.311-9.111 10.371-20.72 18.286-34.819 23.74-14.104 5.46-30.806 8.187-50.113 8.187h-79.726V559.866h79.726z m2.603 207.61c3.468 0 7.81-0.273 13.017-0.82 5.206-0.541 10.738-1.852 16.597-3.93 5.857-2.07 11.658-5.075 17.408-9.005 5.746-3.93 10.902-9.276 15.457-16.045 4.556-6.764 8.298-15.227 11.227-25.378 2.928-10.152 4.393-22.432 4.393-36.84 0-13.967-1.357-26.467-4.068-37.494-2.715-11.021-7.159-20.41-13.342-28.162-6.183-7.746-14.155-13.64-23.916-17.682-9.764-4.037-21.804-6.058-36.122-6.058h-52.066v181.412h51.415z m299.377-207.61v26.198H699.644v74.333H806.38v26.197H699.644v107.08H668.73V559.867h152.618z"
                    fill="#FFFFFF"
                    p-id="3612"
                  ></path>
                </svg>
              </div>
            </div>
            <h5>教师操作手册</h5>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4">
            <div class="card card-1" @click="download('pdf-student')">
              <div class="fjac">
                <svg
                  t="1610617214655"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3606"
                  width="100"
                  height="100"
                >
                  <path
                    d="M863.594 245.432v715.817H271.305c-61.206 0-110.824-49.618-110.824-110.824V63.609h512.095l191.018 181.823z"
                    fill="#D63C3C"
                    p-id="3607"
                  ></path>
                  <path
                    d="M672.576 63.609V264.37h191.018v-18.94z"
                    fill="#A50E0E"
                    p-id="3608"
                  ></path>
                  <path
                    d="M672.576 63.609v181.823h191.018z"
                    fill="#F76969"
                    p-id="3609"
                  ></path>
                  <path
                    d="M686.236 439.996c-9.772-5.192-27.684-4.17-53.29 3.07-17.31 4.93-37.635 12.626-58.563 22.177-11.456-2.745-23.374-5.875-33.472-10.932-27.193-13.548-53.157-35.867-73.263-62.932-0.116-3.05-0.256-5.727-0.423-8.432 0 0-8.245-71.685-18.256-93.769-1.379-3.022-1.918-3.863-3.5-6.072l-1.84-2.365c-5.495-6.56-14.257-12.63-24.15-9.21l-6.098 1.6c-11.177 3.32-18.599 11.75-18.453 20.998 0.686 28.98 20.109 66.85 49.144 114.76l-0.974 9.503c-2.692 26.629-7.78 54.142-12.282 78.437l-0.584 3.161c-4.736 25.52-9.44 47.389-14.003 66.093l-8.108 7.94c-0.599 0.583-14.482 14.19-17.648 17.676-26.984 29.334-42.114 58.02-40.47 76.73 0.522 5.975 3.514 12.93 10.348 14.525l10.689 1.913c4.638 0.82 9.194 0.584 13.551-0.71 23.362-6.94 41.836-44.096 59.823-120.393 38.957-26.859 84.607-53.12 125.737-72.337 38.292 8.73 82.432 9.572 107.903 2.006 4.517-1.341 8.288-2.924 11.205-4.716 4.505-2.746 7.789-6.747 9.177-11.284 2.732-8.907 0.683-19.44-3.76-29.53-1.334-2.998-4.955-6.105-8.426-7.926l-0.014 0.02zM374.245 667.952c0.789-12.917 10.81-40.953 29.683-68.784 1.18-1.738 3.968-6.499 6.51-10.916-13.718 49.334-26.262 71.084-36.193 79.7z m46.65-383.928c7.51-2.231 17.403 15.435 23.024 33.06 5.621 17.626 5.18 31.326 2.763 42.068-8.32-12.37-16.762-33.263-20.94-47.327 0 0-8.302-26.775-4.847-27.801z m27.938 255.427c2.442-10.922 4.967-22.408 7.42-34.528 5.936-29.656 8.547-52.27 10.025-70.655 19.538 19.311 41.162 34.226 64.392 44.423a185.696 185.696 0 0 0 9.103 3.714c-35.543 18.694-65.47 37.471-90.922 57.04l-0.018 0.006z m237.407-72.837c-1.876 2.12-8.218 4.91-12.451 6.167-13.674 4.061-32.447 2.834-59.184-0.288 8.922-3.383 17.164-6.197 24.656-8.423 13.71-4.072 17.767-5.335 32.192-5.9 14.423-0.565 16.663 6.326 14.791 8.463l-0.004-0.019z"
                    fill="#A50E0E"
                    p-id="3610"
                  ></path>
                  <path
                    d="M92.854 494.316h838.292v356.067H92.854z"
                    fill="#F76969"
                    p-id="3611"
                  ></path>
                  <path
                    d="M330.631 559.866c23.43 0 41.165 6.06 53.205 18.175s18.06 29.093 18.06 50.92c0 21.832-6.02 38.86-18.06 51.083-12.04 12.229-29.775 18.23-53.205 18.01h-70.94v95.618h-30.914V559.867h101.854z m-10.413 111.992c17.572 0.22 30.426-3.382 38.561-10.806 8.136-7.42 12.203-18.118 12.203-32.091 0-13.968-4.067-24.61-12.203-31.927-8.135-7.312-20.989-10.97-38.56-10.97h-60.527v85.794h60.526z m199.15-111.992c35.795 0 63.342 9.17 82.653 27.508 19.307 18.337 28.963 46.283 28.963 83.829 0 19.646-2.172 37.059-6.508 52.23-4.342 15.175-11.064 27.946-20.176 38.311-9.111 10.371-20.72 18.286-34.819 23.74-14.104 5.46-30.806 8.187-50.113 8.187h-79.726V559.866h79.726z m2.603 207.61c3.468 0 7.81-0.273 13.017-0.82 5.206-0.541 10.738-1.852 16.597-3.93 5.857-2.07 11.658-5.075 17.408-9.005 5.746-3.93 10.902-9.276 15.457-16.045 4.556-6.764 8.298-15.227 11.227-25.378 2.928-10.152 4.393-22.432 4.393-36.84 0-13.967-1.357-26.467-4.068-37.494-2.715-11.021-7.159-20.41-13.342-28.162-6.183-7.746-14.155-13.64-23.916-17.682-9.764-4.037-21.804-6.058-36.122-6.058h-52.066v181.412h51.415z m299.377-207.61v26.198H699.644v74.333H806.38v26.197H699.644v107.08H668.73V559.867h152.618z"
                    fill="#FFFFFF"
                    p-id="3612"
                  ></path>
                </svg>
              </div>
            </div>
            <h5>学生操作手册</h5>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <div class="full-container footer">
      <div class="subfooter">
        <ul class="support" v-if="false">
          <li>
            <svg
              t="1614045276708"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2195"
              width="20"
              height="20"
            >
              <path
                d="M512 85.333333C276.736 85.333333 85.333333 276.736 85.333333 512v176.768C85.333333 732.458667 123.605333 768 170.666667 768h42.666666a42.666667 42.666667 0 0 0 42.666667-42.666667v-219.434666a42.666667 42.666667 0 0 0-42.666667-42.666667h-38.741333C198.314667 298.112 340.394667 170.666667 512 170.666667s313.685333 127.445333 337.408 292.565333H810.666667a42.666667 42.666667 0 0 0-42.666667 42.666667V768c0 47.061333-38.272 85.333333-85.333333 85.333333h-85.333334v-42.666666h-170.666666v128h256c94.122667 0 170.666667-76.544 170.666666-170.666667 47.061333 0 85.333333-35.541333 85.333334-79.232V512c0-235.264-191.402667-426.666667-426.666667-426.666667z"
                p-id="2196"
                fill="#e6e6e6"
              ></path>
            </svg>
          </li>
          <li>昂立直播课堂技术支持：</li>
          <li>联系电话：13122500673</li>
          <li>联系邮箱：tianliqiang@onlyedu.com</li>
        </ul>
      </div>
      <div class="copyright">
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008443"
          >沪公网安备 31010402008443号</a
        >
        <a href="https://beian.miit.gov.cn/">沪ICP备05013067号-9</a>
        Copyright © 上海昂立教育科技集团有限公司 2020-2021 all right reserved
      </div>
    </div>
    <vs-dialog v-model="dialogActive" width="100px" center>
      <div class="qrcode">
        <qrcode :value="qrcodeUrl" :options="{ width: 200 }" />
        <h6>{{ qrcodeMsg }}</h6>
        <a :href="qrcodeUrl" v-if="clientType === 'android'">下载apk</a>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

Vue.component(VueQrcode.name, VueQrcode)
export default {
  mounted() {
    console.log(this.$commonData)
    this.versionList = this.$commonData.versionList
  },
  components: {},
  filters: {
    pureText(value) {
      value = value.toString()
      const re = /<[^>]+>/gi
      return value.replace(re, '').substring(0, 20) + '...'
    },
  },
  data: () => ({
    active: 'primary',
    dialogActive: false,
    qrcodeUrl: '',
    qrcodeMsg: '',
    versionList: [],
    clientType: '',
  }),
  computed: {
    os: () => {
      return window.navigator.platform
    },
  },
  methods: {
    download(type) {
      switch (type) {
        case 'windows':
          window.location.href = this.$commonData.urls.pc
          break
        case 'mac':
          window.location.href = this.$commonData.urls.mac
          break
        case 'pdf-admin':
          window.open(
            'https://oc-update-1302057473.cos.ap-shanghai.myqcloud.com/docs/昂立直播平台-管理后台.pdf'
          )
          break
        case 'pdf-teacher':
          window.open(
            'https://oc-update-1302057473.cos.ap-shanghai.myqcloud.com/docs/昂立直播平台-教师端.pdf'
          )
          break
        case 'pdf-student':
          window.open(
            'https://oc-update-1302057473.cos.ap-shanghai.myqcloud.com/docs/昂立直播平台-学生端.pdf'
          )
          break
        default:
          break
      }
    },
    openDialog(type) {
      this.dialogActive = true
      this.clientType = type
      switch (type) {
        case 'ios':
          this.qrcodeUrl = this.$commonData.urls.ios
          this.qrcodeMsg = '请用iPhone或iPad扫码安装'
          break
        case 'android':
          this.qrcodeUrl = this.$commonData.urls.android
          this.qrcodeMsg = '请用安卓设备扫码下载安装'
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ml-2 {
  margin-left: 8px;
}
ul {
  padding: 0;
}
ul li {
  list-style-type: none;
}
.bg-color {
  background-image: linear-gradient(
    102.7deg,
    rgba(253, 218, 255, 1) 8.2%,
    rgba(223, 173, 252, 1) 19.6%,
    rgba(173, 205, 252, 1) 36.8%,
    rgba(173, 252, 244, 1) 73.2%,
    rgba(202, 248, 208, 1) 90.9%
  );
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-dark {
  background-color: #e9ecef;
}
.bg-liner {
  background-image: url('https://www.toptal.com/designers/subtlepatterns/patterns/debut_light.png');
}
.section-title-main {
  padding: 15px 0;
  font-size: 30px;
  font-weight: 400;
}
.section-title-sub {
  padding: 0 0 30px 0;
  font-weight: 200;
}
.main-container {
  margin: 0 auto;
  min-height: 100vh;
  text-align: center;

  .full-container {
    width: 100%;

    .heroBanner {
      height: 90vh;
      overflow: hidden;
      background-image: url('../assets/bg.jpg');
      .bannerWrap {
        color: #fff;
        margin: 45px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        h3 {
          padding: 20px 0;
          font-weight: 200;
          margin-top: 80px;
        }
        h1 {
          font-size: 60px;
          font-weight: 400;
          letter-spacing: 10px;
        }
        h5 {
          padding: 0 0 40px 0;
          letter-spacing: 5px;
          font-size: 16px;
        }
        h6 {
          padding: 20px 0;
          font-weight: 100;
        }
      }
    }
  }
  .container {
    width: 1170px;
    padding: 115px 0 160px 0;

    margin: 0 auto;
  }
  .card {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 150px;
    margin: 1rem;
    position: relative;
    width: 200px;
    border-radius: 10px;
    cursor: pointer;
    .flex {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
      .img {
        height: 60%;
      }
      .text {
        font-weight: 600;
      }
    }
  }
  .card-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .card-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .card-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .center {
    text-align: left;
    margin: 50px 0;
  }
  .footer {
    text-align: center;
    background-color: #000000;
    color: #6b6b6b;
    font-size: 13px;
    padding-bottom: 40px;
    .subfooter {
      height: 40px;
      background-color: #0a0a0a;
      display: flex;
      justify-content: center;
      .support {
        display: flex;
        padding: 0;
        margin: 0;
        li {
          padding: 0 15px;

          color: #ffffff;
          display: flex;
          align-items: center;
        }
      }
    }
    .copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      a {
        color: #fff;
        padding-right: 20px;
      }
    }
  }
  .feature-list {
    padding: 20px 0 20px 20px;
  }
}
.qrcode {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
