/* eslint-disable no-irregular-whitespace */ /* eslint-disable
no-irregular-whitespace */ /* eslint-disable no-irregular-whitespace */
<template>
  <div class="textWrapper">
    <p
      style="
        font-size: 18px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: center;
      "
    >
      <strong>【昂立直播课堂】用户服务协议</strong>
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      版本生效时间：【2021】年【9】月【15】日
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      版本更新时间：【2021】年【9】月【15】日
    </p>
    <p style="text-align: left"></p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      欢迎申请使用【运营主体名称】及其关联主体提供的【昂立直播课堂】直播软件。
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >本《【昂立直播课堂】用户服务协议》是您与我方就【昂立直播课堂】直播软件服务等相关事宜所订立的契约，具有法律效力。您（以下或简称“用户”）在使用【昂立直播课堂】直播软件提供的各项服务之前，请务必仔细、审慎阅读并充分理解本协议，</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >注意本协议中有关免除或限制我方责任的条款、权利许可和信息使用的条款、法律适用和争议解决的条款。</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >为使容易辨析，本协议已以加粗形式提示您注意免除或限制我方责任的条款。您在【昂立直播课堂】直播软件进行账号注册过程中勾选“同意”选项即表示您已充分理解并完全接受本协议项下的全部条款。</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >如果您不同意本协议或其附件中任一条款，应立即停止注册程序，我方也将无法为您提供服务。</strong
      >
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      <strong
        >如您是未满18周岁的未成年人，或您因其他原因不具有完全民事行为能力，您应请您的法定监护人阅读本协议并决定是否同意本协议，并特别注意未成年人使用条款。如您是未满14周岁的未成年人，应当由您的监护人为您注册【昂立直播课堂】直播软件的账号，您和您的监护人均受本协议条款的约束，并对账号和服务的使用负责。</strong
      >
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>一、定义</strong>
    </h1>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.1</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >我方</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：【昂立直播课堂】直播软件运营主体的单称或合称，指【运营主体名称】及其关联主体。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.2</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >用户</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：注册、登录、使用本软件的法人、非法人组织或自然人，包括本协议中定义的“机构”和“学生”。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.3</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >机构</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：使用【昂立直播课堂】直播软件的合法成立并存在的学校、企业、其他非企业单位、工作室等。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.4</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >本软件</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：由【运营主体名称】及关联主体提供的【昂立直播课堂】直播软件。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.5</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >本软件规则</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：包括在本软件内已经发表及后续发表的全部规则、实施细则、解读、公告、产品说明等。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.6</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >本服务</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：我方基于互联网以网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务和功能，包括但不限于包括但不限于课程直播、聊天、答题、抢答等。</span
      >
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >1.7</span
      ><strong
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >本协议</strong
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >：指本《【昂立直播课堂】用户服务协议》。
      </span>
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>二、本软件的获取</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      您可以从【运营主体名称】授权的第三方获取本软件，如果您从未授权的第三方获取本软件或与本软件名称相同的安装程序，则【运营主体名称】无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>三、本软件的安装与卸载</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我方为部分不同的终端设备开发了不同的软件版本，请您根据实际情况选择下载合适的版本，并按照该程序提示的步骤正确安装该版本。为提供更加优质、安全的服务，如果您不再需要使用本软件，可以自行卸载。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>四、本软件的更新</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      为了提供更好的用户体验和服务内容，我方不断努力开发新的服务，并为您不时提供软件更新，为了保证本软件及服务的安全性和功能的一致性，我方有权不经向您特别通知而对本软件进行更新，或者对本软件的部分功能效果进行改变或限制。我方不保证旧版本软件可用及相应的客户服务，请您随时更新下载最新版本。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>五、账号注册与管理</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.1.用户注册
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      目前，本软件的账号注册功能仅对机构开放，将由我方给机构提供后台管理账号及密码，您在此同意本软件在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。
    </p>
    <p style="text-align: left">
      <span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >当机构获取后台管理账号后，机构应通过后台管理账号开通本机构的教师及学生端账号，学生端账号应以手机号进行注册，成功注册后，学生可使用账号初始密码“</span
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: rgb(255, 255, 255);
        "
        >123456</span
      ><span
        style="
          font-size: 14px;
          font-family: Roboto;
          color: rgb(17, 17, 17);
          background: transparent;
        "
        >”登录并使用本软件。为免账号被盗、遗失，维护账号使用安全，请用户在账号注册后及时修改密码。</span
      >
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.2.用户登录
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      用户在开通账号后，可通过手机号及密码的方式登录本软件。用户应在登录前仔细阅读本协议及附件，如勾选“同意”选项并继续登录使用本软件，即视为您已充分理解并完全接受本协议及附件项下的全部条款。如您为未成年人（包含十四周岁以下的儿童）或限制民事行为能力人、无民事行为能力人，请您务必在监护人或法定代理人陪同下阅读本协议及附件，并由监护人或法定代理人代理点击确认同意以完成登录行为。我方收集到您的登录记录即视为您及您的监护人或法定代理人已充分阅读、理解且接受本协议的全部内容，本协议对您及您的监护人或法定代理人均具有法律约束力。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.3.账号归属
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      本协议项下的账号所有权归我方所有，用户完成申请注册手续后，仅获得账号的使用权且该使用权仅属于初始申请注册人，不得赠与、借用、租用、转让或售卖账号。因经营需要，我方有权回收用户账号。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.账号安全
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.1.
      您的账号由您自行设置、保管。建议您务必保管好您的账号，为账号设置复杂的密码，并确保您在每个上网时段结束时均以正确步骤退出本软件。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.2.
      账号因您主动泄露、保管疏忽或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.3.
      您的账号只限您本人使用，不得转让或出借，不得将账号及密码提供给其他人共同使用。如我方发现或者有证据证明您的账号被多人共用的，我方有权对您的账号进行限制使用或查封、注销等处理。如您的账号遭到未经授权的使用，您应当立即通知我方，否则未经授权的使用行为均视为您本人的行为，您将自行承担由此导致的所有损失及后果。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.4.
      除我方存在过错外，您应对您账号项下的所有行为及言论承担全部法律责任。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      5.4.5.
      如发现任何未经授权使用您账号访问和/或使用本软件或其他可能导致您账号遭窃、遗失的情况，您应立即通知我方。您理解我方对您的任何请求采取行动均需要合理时间，除我方存在过错外，我方对在采取行动前已经产生的后果不承担任何责任。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>六、用户权利与义务</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.用户承诺：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.1.
      您在使用本软件或本服务过程中，应严格遵守中华人民共和国的各项法律、法规、规章的规定，不得利用本软件制作、上传、发表、复制、查阅和传播下列信息内容（包括但不限于您的账号名称、头像、个人简介、留言、发言、评论等）：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      a)
      任何违反中华人民共和国法律法规规章、公序良俗或民族优秀传统文化的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      b) 任何危害国家统一、主权和领土完整的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      c)
      任何泄露国家秘密、危害国家安全或者损害国家荣誉和利益的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      d)
      任何煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      e)
      任何包含或暗藏性别歧视、种族歧视、地域歧视的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      f)
      任何包含或暗藏邪教、迷信、暴力、色情、赌博或恐怖活动或政治、道德、宗教敏感词汇的图片、文字、音视频或其他信息资料的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      g)
      任何捏造或者歪曲事实，散布谣言，制造社会恐慌、扰乱社会秩序或者破坏社会稳定的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      h)任何教唆他人进行违法犯罪行为的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      i)
      任何具有骚扰性、辱骂性、恐吓性或伤害性，或侵犯公民个人隐私的图片、文字、音视频或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      j)任何包含或暗含二维码或其他商业链接的图片、文字或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      k) 任何包含或暗含垃圾广告、恶意信息、诱骗信息的图片、文字或其他信息资料；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      l)
      任何可能损害我方声誉、商业利益、知识产权或其他合法权益的图片、文字、音视频或其他信息资料。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.2.
      未经我方同意，不得对本软件中存储、处理或者传输的数据和应用程序进行删除、修改或增加等影响本软件正常运行的行为；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.3.
      不得故意制作、传播计算机病毒等破坏性程序或有其他破坏本软件正常运行的行为；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.4.
      未经我方同意，不得向其他本软件用户或其他第三方转让、出借、许可使用、出售您账号下的全部或部分服务；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.5.不得侵犯我方商誉、商业利益、知识产权或其他合法权益；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.6.不得以任何方式打探、窃取或泄露我方商业机密；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.7.不得强制、诱导其他用户关注、点击链接页面或分享信息；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.8.
      未经我方书面许可，不得利用本软件账号和任何功能进行推广或互相推广的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.9.不得以任何方式冒充我方工作人员；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.10. 不得利用任何技术手段批量创建虚假账号；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.11. 不得有其他违反法律、法规、规章、社会公序良俗或本协议约定的行为；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.1.12.
      如您的行为使我方遭受任何损失（包括自身的经济损失、商誉损失及对外支付的赔偿金、补偿金、律师费、诉讼费、公证费等），您应赔偿我方的上述全部损失。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      用户须对自己在本软件的言论和行为承担全部法律责任。我方有权对用户使用我方服务的情况进行独立的审查和监督，并保留屏蔽、删除、断开链接或以其他方式处理上述信息内容并对相关用户的账号予以警告、限制使用或查封、注销等处罚而无须通知用户的权利。如账号因上述原因受到处罚，用户可以通过本协议第11.2条约定的方式向我方提交申诉，我方将对申诉进行审查，并自行合理判断决定是否变更处罚。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.用户必须自行准备如下设备和承担如下开支：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.1.
      上网设备，包括但不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.2.上网开支，包括但不限于网络接入费、上网设备租用费、手机流量费等；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.3.本软件电脑终端最低配置：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      Win电脑：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      Mac电脑：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.2.4.本软件移动终端最低配置：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      Android设备：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      iOS设备：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.3.多设备限制登录说明：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      为保护账号安全，避免账号密码因共享泄露被盗，您的账号最多只能在【】台设备上同时登录，分别如下：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      移动端：iOS、Android、iPad，可同时登录任意【】台；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      客户端：win版、mac版，可同时登录任意【】台。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      6.4.您可通过本软件上传、发表或传输相关内容，包括但不限于文字、软件、程序、课件、图形、图片、声音、音乐、视频、音视频、链接等信息或其他资料（下称“内容”），但您应享有相关知识产权或经过第三方合法授权，您应对上传、发表或传输上述内容承担全部法律责任。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>七、用户个人信息保护</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      我方深知个人信息对用户的重要性，将按照法律法规等要求，采取相应安全保护措施，尽力保护用户的个人信息安全可控。您在使用本软件或本服务前，应当完整地阅读本协议附件《【昂立直播课堂】用户个人信息保护政策》。如您不同意《【昂立直播课堂】用户个人信息保护政策》的任何内容，您应当立即停止使用本软件或本服务。您使用本软件或本服务即代表您同意我方根据本协议及《【昂立直播课堂】用户个人信息保护政策》合法地收集、使用、存储、处理您的个人信息，具体约定详见本协议附件《【昂立直播课堂】用户个人信息保护政策》。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>八、服务的变更、中断、终止</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.1.我方可根据法律法规的变化或我方调整服务的需要，不时修改本协议或本软件规则，变更后的协议或本软件规则将通过本协议第11.1条约定的方式通知您。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.2.如您不同意对本协议或本软件规则的变更，您应当于变更内容生效之日起停止使用本软件；如您在变更内容生效后仍继续使用本软件，则视为您同意已生效的变更内容。除我方另行明确声明外，任何使本软件服务范围扩大或功能增强的新内容均受本协议约束。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.3.鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他我方无法控制的情形），您同意我方有权单方中断部分或全部的服务（包括收费网络服务）。如中断的网络服务属于免费网络服务，我方无需通知您，也无需对任何用户或任何第三方承担任何责任；如中断的网络服务属于收费网络服务，我方将在中断之前尽可能通过本协议第11.1条约定的方式通知您，并向您提供等值的替代性的收费网络服务，如您不愿意接受替代性的收费网络服务，就您已向我方支付的费用，我方将按照您实际使用相应收费网络服务的情况，扣除相应费用之后将剩余费用退还给您。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.4.您理解并明确同意，我方需要定期或不定期地对本软件或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，我方无需为此承担任何责任，但我方应尽可能事先进行通告。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.如发生下列情形之一，我方有权终止本协议：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.1.
      您违反法律法规、本软件规则的规定或本协议的约定，我方依据该规定或约定对您的账号予以查封、注销的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.2.
      您转让本人账号、盗用他人账号或采取不正当手段进行谋利，我方依据本软件规则规定或本协议约定对您的账号予以查封、注销的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.3.
      您在使用本软件时有任何侵犯第三方合法权益或侵犯我方商誉、商业利益、知识产权等合法权益的行为；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.4.本协议更新时，您明示拒绝接受新的用户服务协议的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.5.因不可抗力导致我方不能履行本协议的；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.5.6.
      其他根据相关法律法规我方应当终止本协议或我方有合理理由认为需终止向您提供服务的情况。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      8.6.本协议终止后，我方将停止收集和使用您的个人信息，但可继续保存您留存于本软件的其他内容和信息；除法律法规另有规定，我方无义务向您或您指定的第三方披露您账号中的任何信息。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>九、免责声明</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.1.您理解并明确同意，因您的终端设备、网络信号、通信线路等出现故障或因自身操作不当而产生的一切后果均由自己承担，我方不承担任何责任。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.2.我方不担保所提供的服务一定能满足您的要求，也不担保本服务不会中断，对本服务的及时性、安全性、准确性也都不作担保。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.3.我方对于本软件中第三方链接的网站或资源的内容、隐私政策和活动无权控制、审查或修改，因而也不承担任何责任。我方建议您在离开本软件，访问其他网站或资源前仔细阅读其服务条款和隐私政策。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.4.除我方存在过错外，您应对您账号项下的所有行为（包括但不限于发表信息、购买服务、披露信息等）承担全部法律责任。对于因您将账号或密码提供或泄露给任何第三方而造成的损失，我方不承担任何责任。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.5.您理解并明确同意，对于本软件向您提供的下列服务所引发的任何损失，我方无需承担任何责任：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.5.1. 我方向您免费提供的各项网络服务；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.5.2. 我方向您赠送或附赠的任何服务。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.6.您理解并明确同意，对于因不可抗力或我方不能控制的原因造成的本软件服务中断或其他缺陷，我方不承担任何责任，但我方将尽力减少因此而给您造成的损失和影响。其中不可抗力是指黑客活动、计算机病毒、电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或其他我方无法预见、不能避免且不能克服的事件。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.7.您理解并知晓,在使用本软件时，您所接触的内容和信息来源广泛，我方无法对所有内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。您理解并知晓您可能会接触到不正确的、令人不快的、不适当的或令人厌恶的内容和信息，您不会以此追究我方的相关责任。我方不对任何用户在本软件上传、发表或传输的任何内容和信息进行背书或推荐，也不对上述内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对上述内容和信息的任何使用需自行承担相关的风险。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.8.您理解并明确同意，如因我方过错导致您不能享受相应服务时，我方承担的责任以您已实际支付的费用为限。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      9.9.您理解并明确同意，我方在提供产品或服务的过程中有权以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在本软件的任何位置上投放广告，在您上传、传播的内容中投放广告），您同意接受我方通过电子邮件、站内短信、手机短信、网站公告或其他方式向您发送促销或其他相关商业信息。除法律法规明确规定外，我方不对因该广告信息进行的交易所遭受的损失或损害承担任何责任。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>十、知识产权</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.1.除非另有约定或我方另行声明，本软件内的所有内容（包括但不限于程序代码、技术、界面设计、版面框架、数据资料、文字、图片、图表、动画、音频、视频、色彩、电子文档等信息）的所有知识产权及相关权利，均归我方所有；用户在使用本服务中所上传的内容的知识产权归用户或法律规定的相关权利人所有。未经相关权利人书面同意，任何人不得侵犯我方或其他相关权利人的知识产权及相关权利（包括但不限于对上述内容或信息进行复制、录屏、传播、转载、展示、镜像、上传、下载、修改、出租、出售、向第三方提供等方式），否则将承担全部法律责任。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.2.本软件所包含的Logo、图形及其组合、其他标识或徵记等均为我方依法享有的商标，未经我方书面授权，任何人不得以任何方式进行使用、复制、修改、传播或与其它产品捆绑使用，也不得向任何人表明（明示或暗示）您有权展示、使用或作其他处理。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.3.本软件系统为我方独立研发，未经我方许可，任何人不得对本软件进行反向工程、反向编译或反汇编，不得将上述内容或信息在任何媒体直接或间接发表、播放、出于播放或发表目的而改写或再发行，或者用于其他任何目的。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      10.4.如您认为您的知识产权或其他合法权益被侵犯，请通过本协议第11.2条约定的方式及时与我方联系，并向我方提交证明权属关系及侵权行为等基本事实的相关材料。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>十一、通知</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.您同意我方以下列方式之一向您送达各类通知：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.1. 公示的文案；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.2. 站内消息、弹窗消息、客户端推送的消息；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.3. 通过您账号绑定的第三方软件（如微信）；
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.1.4.
      根据您预留于本软件的联系方式发出的电子邮件、手机短信、函件、人工电话联系等。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      11.2.如您有任何问题需要咨询或有任何投诉、申诉或建议，可以通过以下方式与我方联系，我方将尽快审核相关问题、投诉或建议，并于15个工作日内予以回复：
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      运营主体名称：【上海新南洋教育科技有限公司】
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      注册地址：【上海市徐汇区番禺路667号6楼C座】
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      联系电话：【021-52588866】
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>十二、争议解决</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.1.本协议的订立、执行、解释和争议的解决均适用现行中华人民共和国法律法规。如发生本协议部分条款与中华人民共和国法律法规相抵触时，则这些条款将完全按法律法规的规定重新解释。如本协议任一条款被认定为废止、无效或不可执行，该条应视为可分的且并不影响本协议其他条款的有效性及可执行性。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      12.2.您因使用本软件或与本软件有关的争议，由我方与您友好协商解决；协商不成时，任何一方均可向【运营主体名称】所在地人民法院提起诉讼。
    </p>
    <h1
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
      "
    >
      <strong>十三、其他</strong>
    </h1>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      13.1.本协议内容同时包括《【昂立直播课堂】用户个人信息保护政策》，本协议附件一经正式发表，即为本协议不可分割的组成部分，您应同样遵守。本协议与《【昂立直播课堂】用户个人信息保护政策》不一致的，以《【昂立直播课堂】用户个人信息保护政策》约定为准。您对前述任何单独协议的接受，均视为您对本协议全部的接受。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      13.2.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
    </p>
    <p
      style="
        font-size: 14px;
        font-family: Roboto;
        color: rgb(17, 17, 17);
        background: transparent;
        text-align: left;
      "
    >
      13.3.本协议所有条款的标题仅为阅读方便，不作为本协议涵义解释或限制的依据。
    </p>
    <p style="text-align: left"></p>
  </div>
</template>
<script>
export default {
  data: () => ({}),
}
</script>
<style lang="scss" scoped>
.textWrapper {
  .head {
    text-align: center;
  }

  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  padding: 20px;
}
</style>
