import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import './app.css'
import 'vuesax/dist/vuesax.css'

import device from 'vue-device-detector'
import VueClipboard from 'vue-clipboard2'

Vue.use(Vuesax, {
  // options here
})
Vue.use(device)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.config.productionTip = false

const commonData = require('../data.json')

Vue.prototype.$commonData = commonData

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
