<template>
  <div class="textWrapper">
    <h3 class="head">第三方SDK收集使用信息说明</h3>
    <p>
      为保障昂立直播课堂App的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。
    </p>
    <p>
      我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。目前昂立直播课堂App接入的第三方SDK列明如下：
    </p>
    <h4>腾讯云实时音视频</h4>
    <p>功能：用于直播功能</p>
    <p>收集个人信息类型：设备信息，网络信息，运营商信息。</p>
    <p>
      隐私权政策链接：<a
        href="https://cloud.tencent.com/document/product/301/11470"
        >https://cloud.tencent.com/document/product/301/11470</a
      >
    </p>
    <h4>腾讯云即时通信IMSDK</h4>
    <p>功能：用于用户实时通信功能</p>
    <p>收集个人信息类型：设备信息，网络信息，运营商信息。</p>
    <p>
      隐私权政策链接：<a
        href="https://cloud.tencent.com/document/product/269/58094"
        >https://cloud.tencent.com/document/product/269/58094</a
      >
    </p>
    <h4>腾讯浏览器SDK</h4>
    <p>功能：用于提供用户更好的Web浏览体验</p>
    <p>收集个人信息类型：设备标识符（IMEI），网络设备硬件信息，网络信息。</p>
    <p>
      隐私权政策链接：<a href="https://x5.tencent.com/docs/privacy.html"
        >https://x5.tencent.com/docs/privacy.html</a
      >
    </p>

    <h4>其他未收集个人信息的第三方SDK：</h4>
    <p>OkHttp</p>
    <p>功能：网络处理框架</p>
    <p>Glide</p>
    <p>功能：图片处理框架</p>
    <p>Gson</p>
    <p>功能：文件处理</p>
    <p>Retrofit2</p>
    <p>功能：网络处理</p>
    <p>腾讯云互动白板</p>
    <p>功能：多人实时白板互动</p>
  </div>
</template>
<script>
export default {
  data: () => ({}),
}
</script>
<style lang="scss" scoped>
.textWrapper {
  .head {
    text-align: center;
  }

  margin: 0 auto;
  text-align: left;
  font-size: 14px;
  padding: 20px;
}
</style>
