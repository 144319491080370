import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AndroidProtocol from '../views/AndroidProtocol.vue'
import AndroidPrivacyAgreement from '../views/AndroidPrivacyAgreement.vue'
import AndroidUserAgreement from '../views/AndroidUserAgreement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/mobile',
    name: 'Mobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Mobile.vue'),
  },
  {
    path: '/android-protocol',
    name: 'AndroidProtocol',
    component: AndroidProtocol,
  },
  {
    path: '/android-privacy-agreement',
    name: 'AndroidPrivacyAgreement',
    component: AndroidPrivacyAgreement,
  },
  {
    path: '/android-user-agreement',
    name: 'AndroidUserAgreement',
    component: AndroidUserAgreement,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  console.log(to, from, next)
  if (to.path === '/') {
    if (Vue.$device.mobile) {
      router.push('/mobile')
    }
  }
  next()
})

export default router
